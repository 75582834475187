
.btn{
  border-radius:50%;
  width:40px;
  height:40px;
  display:flex;
  justify-content:center;
  align-items:center;
}
.btn-icon{
  width:24px;height:24px;
}
#feeding {
  background: #fff;
  height: 100vh;
  width: 100vw;
}
.header{
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction: column;
  .times{
      .num{
        color:#fff;
        font-size: 50px;
        padding:0 10px;
      }
      .unit{
        font-size: 16px;
        color:#fff;
      }
  }
  .weight{
     color:#fff;
     font-size: 14px;
     
  }
  .detail{
       margin-top: 10px;
   }
}

.btn-mini{
      margin-top: 10px;
      border-radius: 15px;
      color: #fff;
      background: #ccc;
      font-size: 14px;
      padding:5px 10px;
      min-width: 80px;
      text-align: center;
      border:solid 1px #fff;
}
.btn-primary{
      margin-top: 10px;
      border-radius: 15px;
      color: #fff;
      background: #ff3333;
      font-size: 14px;
      padding:5px 10px;
      min-width: 80px;
      text-align: center;
      border:solid 1px #fff;
}

.map-content {
  min-height: 100px;
  height: 100%;
}


.colorPad {
  background: pink;
  background:-webkit-linear-gradient(#ff3333, #ff00ff, #ff3333);
  background:-o-linear-gradient(#ff3333, #ff00ff, #ff3333);
  background:-moz-linear-gradient(#ff3333, #ff00ff, #ff3333);
  background: linear-gradient(#ff3333, #ff00ff, #ff3333);
  height: 100%;
  overflow: hidden;
  padding-top: 50px;
  .box {
    border-radius: 10px;
    min-height: 100px;
    background: #fff;
    margin: 20px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
  .box .head {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    padding:2px 20px;
    align-items: center;
    border-bottom: #eee 1px solid;
  }
  .box .body {
    display: flex;
    justify-content: flex-start;
    padding:20px 20px 20px 20px;
    flex: 1;
  }
  .box:first-child {
    margin-top: 0;
  }
}
